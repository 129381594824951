"use client";
import React, { HTMLAttributes, useContext, useEffect, useState } from "react";
import Icon from "@/components/primitives/icon";
import Form from "@/components/primitives/form";
import Checkbox from "@/components/primitives/checkbox";
import Link from "next/link";
import Button from "../primitives/button";
import { useQueryUpdater } from "../navigation/query-updater";
import { useRouter } from "next/navigation";
import { SignIn } from "@clerk/nextjs";
import { useSignIn } from "@clerk/nextjs";
import { useSearchParams } from "next/navigation";
import Info from "../primitives/info";
import { LoadingCircle } from "../primitives/loading";
import { AuthContext } from "@/app/(public)/drawer";

export default function LoginComponent() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);
  const { createQueryParamString, removeQueryParam } = useQueryUpdater();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { setAuth, closeAuth } = useContext(AuthContext);
  const { isLoaded, signIn, setActive } = useSignIn();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [expired, setExpired] = React.useState(false);
  const [verified, setVerified] = React.useState(false);

  const [emailDisplay, setEmailDisplay] = useState("");

  if (!isLoaded) {
    return null;
  }

  const { startEmailLinkFlow, cancelEmailLinkFlow } =
    signIn.createEmailLinkFlow();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }
    setExpired(false);
    setVerified(false);
    setLoading(true);
    const formData = new FormData(e.currentTarget);

    try {
      const si = await signIn.create({
        identifier: formData.get("username") as string,
        // password: formData.get("password") as string,
      });
      console.log(si);
      const { emailAddressId, safeIdentifier } = si.supportedFirstFactors.find(
        (ff) => ff.strategy === "email_link"
      );

      setEmailDisplay(safeIdentifier);

      setWaiting(true);
      setLoading(false);
      const res = await startEmailLinkFlow({
        emailAddressId: emailAddressId,
        redirectUrl: process.env.NEXT_PUBLIC_CLERK_VERIFICATION_URL!,
      });

      const verification = res.firstFactorVerification;
      if (verification.verifiedFromTheSameClient()) {
        setVerified(true);
        setSuccess(true);
        setLoading(false);
        setWaiting(false);
        // If you're handling the verification result from
        // another route/component, you should return here.
        // See the <Verification/> component as an
        // example below.
        // If you want to complete the flow on this tab,
        // don't return. Simply check the sign in status.
        // return;
      } else if (verification.status === "expired") {
        setExpired(true);
      }
      if (res.status === "complete") {
        setActive({ session: res.createdSessionId });
        setSuccess(true);
        setLoading(false);
        const returnTo = searchParams.get("return");
        if (returnTo) {
          router.replace(returnTo);
        } else {
          closeAuth();
        }
        //Handle redirect
        return;
      }

      // if (result.status === "complete") {
      //   console.log(result);
      //   await setActive({ session: result.createdSessionId });
      //   setSuccess(true);
      //   setLoading(false);
      //   const returnTo = searchParams.get("return");

      //   if (returnTo) {
      //     router.replace(returnTo);
      //   } else {
      //     closeAuth();
      //   }
      // } else {
      //   /*Investigate why the login hasn't completed */
      //   console.log(result);
      //   setLoading(false);
      // }
      setError(null);
    } catch (err: any) {
      console.error("error", err.errors[0].longMessage);
      setError(err.errors[0].longMessage);
      setLoading(false);
    }
  };

  const signInWithGoogle = () =>
    signIn.authenticateWithRedirect({
      strategy: "oauth_google",
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/",
    });

  // const [formmsg, setFormMsg] = useState("");
  // const formRef = React.useRef<React.JSX.Element>(null);

  if (expired) {
    return <div>Magic link has expired</div>;
  }

  if (verified) {
    return <div>Signed in on other tab</div>;
  }
  return (

        <div className="flex flex-col gap-14 justify-center w-full max-w-md">
          {success ? (
            <>
              <Info.Block
                variant="success"
                title="Success"
                message="You have successfully logged in."
              />
            </>
          ) : loading ? (
            <div className="w-full h-96 flex justify-center items-center">
              <LoadingCircle />
            </div>
          ) : waiting ? (
            <div className="w-full h-96 flex flex-col justify-center items-center gap-10 text-center">
              <h2 className="text-display-lg leading-none text-center">
                Please check your inbox
              </h2>
              <div className="text-body-medium [text-wrap:balance]">
                Click the link we sent to {emailDisplay} to sign in.
              </div>
              <div></div>
            </div>
          ) : (
            <>
              <h2 className="text-display-xl leading-none uppercase">Login</h2>
              <div className="flex flex-col gap-6">
                {/* <SignIn /> */}
                {error && (
                  <Info.Inline variant="error" message={error} filled />
                )}

                <Form
                  className="flex flex-col w-full gap-6"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  {/* <div>{formmsg}</div> */}
                  <Form.Field name="username">
                    <Form.Label>Email address or username</Form.Label>
                    <Form.Input
                      type="text"
                      placeholder="Email address or username"
                      required
                      autoFocus
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    >
                      Email address or username
                    </Form.Input>
                    <Form.Message match="valueMissing">
                      Please fill out this field.
                    </Form.Message>
                  </Form.Field>
                  {/* <Form.Field name="password">
                    <Form.Label>Password</Form.Label>
                    <Form.PasswordInput
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    >
                      Password
                    </Form.PasswordInput>
                    <Form.Message match="valueMissing">
                      Please fill out this field.
                    </Form.Message>
                  </Form.Field> */}
                  <Form.Submit variant="filled" className="order-2">
                    Login
                  </Form.Submit>
                  <div className="flex flex-row gap-6 justify-between order-1">
                    <div className="flex text-sm">
                      {/* <Form.Field name="remember_me">
                    <Checkbox
                    id="remember_me"
                    label="Remember me"
                    name="remember_me"
                    />
                  </Form.Field> */}
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => setAuth("forgot-password")}
                        className="underline text-sm text-secondary-300"
                      >
                        Forgot password?
                      </button>
                    </div>
                  </div>
                </Form>

                <div className="gap w-full text-center">
                  <p className="text-sm text-secondary-500 gap-text">or</p>
                </div>
                <button
                  onClick={() => signInWithGoogle()}
                  className="btn-social h-11"
                >
                  <img
                    src="/logos/google-logo.svg"
                    alt="Google Logo"
                    className="mr-2"
                  />
                  <img src="/logos/google-text.svg" alt="Google Logo" />
                </button>
                <p className="text-sm text-secondary-300 text-center">
                  Not a member?{" "}
                  <button
                    onClick={() => setAuth("register")}
                    className="underline text-primary-200"
                  >
                    Create an account
                  </button>
                </p>
              </div>
            </>
          )}
    </div>
  );
}
