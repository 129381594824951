"use client";
import React, { Suspense, useContext, useEffect, useState } from "react";
import Icon from "@/components/primitives/icon";
import Form from "@/components/primitives/form";
import Checkbox from "@/components/primitives/checkbox";
import Button from "../primitives/button";
import Link from "next/link";
import { useQueryUpdater } from "../navigation/query-updater";
import { useRouter, useSearchParams } from "next/navigation";
import { useSignUp } from "@clerk/nextjs";
import Info from "../primitives/info";
import { LoadingCircle } from "../primitives/loading";
import { CheckedState } from "@radix-ui/react-checkbox";
import { AuthContext } from "@/app/(public)/drawer";

/* Get the months */
const months: Option[] = Array.from({ length: 12 }, (_, index) => ({
  value: index.toString(),
  label: new Date(0, index).toLocaleString("en-US", { month: "long" }),
}));

/* Get the birth day */
const days: Option[] = Array.from({ length: 31 }, (_, index) => ({
  value: (index + 1).toString().padStart(2, "0"),
  label: (index + 1).toString().padStart(2, "0"),
}));

/* Get the birth year */
const currentYear = new Date().getFullYear();
const maxYear = currentYear - 18;
const minYear = currentYear - 100;
const years: Option[] = Array.from(
  { length: maxYear - minYear + 1 },
  (_, index) => ({
    value: (maxYear - index).toString(),
    label: (maxYear - index).toString(),
  })
);

export default function CreateAccountComponent() {
  const [error, setError] = useState<string | null>(null);
  const [birthdateError, setBirthdateError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [profileCreationError, setProfileCreationError] =
    useState<boolean>(false);
  const [pendingVerification, setPendingVerification] = useState(false);
  const [code, setCode] = useState("");
  const [updatingExistingSignup, setUpdatingExistingSignup] = useState(false);
  const { createQueryParamString, removeQueryParam } = useQueryUpdater();
  const router = useRouter();
  const { setAuth, closeAuth } = useContext(AuthContext);

  const { isLoaded, signUp, setActive } = useSignUp();

  const [expired, setExpired] = React.useState(false);
  const [verified, setVerified] = React.useState(false);

  const searchParams = useSearchParams();
  const oauthParam = searchParams.get("oauth");
  const [oauthContinue, setOauthContinue] = useState<boolean>(
    oauthParam === "true"
  );

  const [fieldData, setFieldData] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    username: string;
    password: string;
    newsletter: CheckedState;
  }>({
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    password: "",
    newsletter: true,
  });

  interface Birthdate {
    day: string | null;
    month: string | null;
    year: string | null;
    formatted: Date;
    valid: boolean;
  }

  const [birthdate, setBirthdate] = useState<Birthdate>({
    day: null,
    month: null,
    year: null,
    formatted: new Date(),
    valid: false,
  });
  const [dropdownDays, setDropdownDays] = useState<Option[]>(days);
  const [dropdownYears, setDropdownYears] = useState<Option[]>(years);

  const handleBirthdateChange = (field: string, value: string) => {
    setBirthdate({
      ...birthdate,
      [field]: value,
    });
  };

  useEffect(() => {
    if (!birthdate.month) return;
    const month = parseInt(birthdate.month) + 1;
    const daysInMonth = new Date(
      birthdate.year ? parseInt(birthdate.year) : new Date().getFullYear(),
      month,
      0
    ).getDate();

    const dayNoLongerValid =
      birthdate.day && parseInt(birthdate.day, 10) > daysInMonth;

    setDropdownDays(
      Array.from({ length: daysInMonth }, (_, index) => ({
        value: (index + 1).toString().padStart(2, "0"),
        label: (index + 1).toString().padStart(2, "0"),
      }))
    );

    if (!birthdate.day || !birthdate.month || !birthdate.year) return;

    const birthDate = new Date();
    birthDate.setFullYear(parseInt(birthdate.year));
    birthDate.setMonth(parseInt(birthdate.month));
    birthDate.setDate(parseInt(birthdate.day));
    birthDate.setHours(0);
    birthDate.setMinutes(0);
    birthDate.setSeconds(0);

    const currentDate = new Date();

    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    const overEighteen = birthDate <= eighteenYearsAgo;

    setBirthdate({
      ...birthdate,
      day: dayNoLongerValid ? null : birthdate.day,
      year: overEighteen
        ? birthdate.year
        : (parseInt(birthdate.year) - 1).toString(),
      formatted: birthDate,
      valid: overEighteen && birthDate <= currentDate && !dayNoLongerValid,
    });
  }, [birthdate.day, birthdate.month, birthdate.year]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (
      // signUp.status === "missing_requirements" &&
      // !signUp.missingFields.includes("password")
      oauthContinue
    ) {
      if (signUp.unverifiedFields.includes("email_address")) {
        // setPendingVerification(true);
        return;
      }
      setFormOpen(true);
      setFieldData((prev) => {
        return {
          ...prev,
          email: signUp.emailAddress || "",
          firstName: signUp.firstName || "",
          lastName: signUp.lastName || "",
          username: signUp.username || "",
        };
      });
    }

    if (signUp.status === "complete") {
      setActive({ session: signUp.createdSessionId });
      waitForProfile();
    }
  }, [isLoaded, signUp]);

  type FieldName = "firstName" | "lastName" | "email" | "username";
  const maxLengths: Record<FieldName, number> = {
    firstName: 50,
    lastName: 50,
    username: 18,
    email: 100,
  };
  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    const fieldName = name as FieldName;
    if (name in maxLengths && value.length <= maxLengths[fieldName]) {
      setFieldData({
        ...fieldData,
        [name]: value,
      });
    }
  };
  const handleSubmit = async (e: {
    preventDefault: () => void;
    currentTarget: any;
  }) => {
    e.preventDefault();
    if (!isLoaded) return;

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      return;
    }
    const birthdateValid = await verifyFields();
    if (!birthdateValid) return;

    if (oauthContinue && !updatingExistingSignup) {
      console.log("update");
      await updateUser();
      return;
    } else {
      console.log("create");
      setUpdatingExistingSignup(false);
      await createUser();
    }
  };

  const verifyFields = async () => {
    if (!birthdate.valid) {
      setBirthdateError(true);
      return false;
    }
    setBirthdateError(false);

    setError(null);
    return true;
  };

  const createUser = async () => {
    if (!isLoaded) {
      return;
    }
    const { startEmailLinkFlow, cancelEmailLinkFlow } =
      signUp.createEmailLinkFlow();
    setExpired(false);
    setVerified(false);

    setLoading(true);

    try {
      await signUp.create({
        emailAddress: fieldData.email,
        firstName: fieldData.firstName,
        lastName: fieldData.lastName,
        username: fieldData.username,
        // password: fieldData.password,

        unsafeMetadata: {
          dateOfBirth: birthdate.formatted,
          newsletterSignup: fieldData.newsletter,
          displayName: fieldData.username,
        },
      });

      setError(null);
      setWaiting(true);
      setLoading(false);
      console.log(process.env.NEXT_PUBLIC_CLERK_VERIFICATION_URL);
      const su = await startEmailLinkFlow({
        redirectUrl:
          (process.env.NEXT_PUBLIC_CLERK_VERIFICATION_URL || "") +
          "&continueregistration",
      });

      console.log(su);

      const verification = su.verifications.emailAddress;
      if (verification.verifiedFromTheSameClient()) {
        setVerified(true);
        // If you're handling the verification result from
        // another route/component, you should return here.
        // See the <MagicLinkVerification/> component as an
        // example below.
        // If you want to complete the flow on this tab,
        // don't return. Check the sign up status instead.
        console.log("verified from the same client");
        // return;
      } else if (verification.status === "expired") {
        setExpired(true);
        setWaiting(false);
      }

      if (su.status === "complete") {
        // Sign up is complete, we have a session.
        // Navigate to the after sign up URL.
        console.log("STATUS COMPLETE");
        setActive({
          session: su.createdSessionId,
          // beforeEmit: () => router.push("/after-sign-up-path"),
        });
        waitForProfile();
        return;
      }
    } catch (err: any) {
      console.error(err);
      setError(err.errors[0].longMessage);

      setLoading(false);
    }
  };

  const updateUser = async () => {
    if (!isLoaded) {
      return;
    }

    setLoading(true);

    try {
      await signUp.update({
        // emailAddress: fieldData.email,
        firstName: fieldData.firstName,
        lastName: fieldData.lastName,
        username: fieldData.username,
        // password: fieldData.password,
        unsafeMetadata: {
          dateOfBirth: birthdate.formatted,
          newsletterSignup: fieldData.newsletter,
          displayName: fieldData.username,
        },
      });

      if (signUp.status === "complete") {
        router.push(removeQueryParam("oauth"));
        await setActive({ session: signUp.createdSessionId });
        setAuth("successful-creation");
        setError(null);
        // setLoading(false);
      } else if (signUp.status === "missing_requirements") {
        if (signUp.unverifiedFields.includes("email_address")) {
          setPendingVerification(true);
          setLoading(false);
          return;
        }
      }
    } catch (err: any) {
      console.error(err);
      setError(err.errors[0].longMessage);
      setLoading(false);
    }
  };

  const onPressVerify = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (!isLoaded) {
      return;
    }

    setLoading(true);

    try {
      const completeSignUp = await signUp.attemptEmailAddressVerification({
        code,
      });
      if (completeSignUp.status !== "complete") {
        /*  investigate the response, to see if there was an error
         or if the user needs to complete more steps.*/
        console.log(JSON.stringify(completeSignUp, null, 2));
      }
      if (completeSignUp.status === "complete") {
        console.log("sign up completed");
        setPendingVerification(false);
        await setActive({ session: completeSignUp.createdSessionId });
        waitForProfile();
      }
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      setError(err.errors[0].longMessage);
      setLoading(false);
    }
  };

  const resendVerification = async () => {
    if (!isLoaded) {
      return;
    }

    try {
      setError(null);
      setLoading(true);
      setPendingVerification(true);
      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
      setLoading(false);
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      setError(err.errors[0].longMessage);
      setLoading(false);
    }
  };

  const signUpWithGoogle = async () => {
    if (!isLoaded) {
      return;
    }

    try {
      await signUp.authenticateWithRedirect({
        strategy: "oauth_google",
        redirectUrl: "/sso-callback",
        redirectUrlComplete: "/",
      });
    } catch (err: any) {
      console.error(JSON.stringify(err, null, 2));
      setError(err.errors[0].longMessage);
    }
  };

  const waitForProfile = async () => {
    setLoadingProfile(true);
    setLoading(true);
    let count = 0;
    while (count < 5) {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}/api/users/${signUp?.username}/profiles`
      );

      if (res.ok) {
        const data = await res.json();
        // setLoadingProfile(false);
        // setLoading(false);
        setAuth("successful-creation");
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      count++;
    }
    setProfileCreationError(true);
    setLoadingProfile(false);
    // setLoading(false);
  };

  const changeDetails = () => {
    setUpdatingExistingSignup(true);
    setPendingVerification(false);
  };

  if (expired) {
    return <div>Magic link has expired</div>;
  }

  if (verified) {
    return <div>Signed in on other tab</div>;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {/* <div
            className={`absolute top-12 left-10 max-md:left-6 max-md:top-8 flex justify-start items-center ${
              loadingProfile ? "invisible" : ""
            }`}
          >
            <Button
              variant="circle"
              color="tertiary"
              onClick={() => closeAuth()}
            >
              <Icon name="close" className="fill-secondary-300" size="sm" />
            </Button>
          </div> */}

      {loading ? (
        <div className="flex flex-col justify-center items-center gap-12 w-full h-96">
          {loadingProfile && (
            <div className="flex justify-center items-center w-full text-center text-headline-lg">
              Please wait while your profile is created...
            </div>
          )}
          <LoadingCircle />
        </div>
      ) : waiting ? (
        <div className="w-full h-96 flex flex-col justify-center items-center gap-10 text-center">
          <h2 className="text-display-lg lg:text-display-xl leading-none text-center">
            Please check your inbox
          </h2>
          <div className="text-body-medium [text-wrap:balance]">
            Click the link we sent to {fieldData.email} to continue.
          </div>
          <div></div>
        </div>
      ) : profileCreationError ? (
        <div className="flex flex-col justify-center items-center gap-12 w-full h-96 text-center">
          <Info.Block
            title="Uh oh..."
            variant="error"
            message="Your account creation was successful, but creating your profile took longer than expected. If you continue to have issues viewing your profile, please contact support."
          />
        </div>
      ) : (
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col justify-center items-center gap-6 w-full max-w-md">
            {isLoaded && !pendingVerification && !oauthContinue && (
              <h2 className="mb-6 text-center text-display-lg lg:text-display-xl uppercase">
                Join the future of sports picking
              </h2>
            )}

            {isLoaded && oauthContinue && (
              <h2 className="mb-6 text-center text-display-lg lg:text-display-xl uppercase">
                Finish creating your account
              </h2>
            )}
            {!formOpen && !pendingVerification && (
              <>
                {isLoaded && !oauthContinue && (
                  <div className="flex flex-col w-full gap-y-4">
                    <p className="uppercase text-secondary-300 text-title-bold text-center">
                      Register with
                    </p>

                    <button
                      onClick={() => setFormOpen(true)}
                      className="w-full h-11 btn-social text-secondary-300"
                    >
                      <div className="gap-2 flex items-center">
                        <Icon name="mail-filled" size="sm" />
                        <span className="text-title-bold uppercase">Email</span>
                      </div>
                    </button>
                    <button
                      onClick={() => signUpWithGoogle()}
                      className="w-full h-11 btn-social"
                    >
                      <img
                        src="/logos/google-logo.svg"
                        alt="Google Logo"
                        className="mr-2"
                      />
                      <img src="/logos/google-text.svg" alt="Google Logo" />
                    </button>
                  </div>
                )}

                <p className="text-center text-secondary-300 text-sm pt-4">
                  Already have an account?{" "}
                  <button
                    onClick={() => setAuth("login")}
                    className="text-primary-200 underline"
                  >
                    Login
                  </button>
                </p>

                {/* <p className="mt-4 mb-4 text-center text-secondary-500 text-sm">
                    By signing up, you acknowledge that you have read, understand, and agree to Moneyline's{" "}
                    <a href="/terms-conditions" className="underline">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a href="/privacy-policy" className="underline">
                      Privacy Policy
                    </a>
                    .
                  </p> */}
              </>
            )}

            {formOpen && !pendingVerification && (
              <>
                <Form
                  className="flex flex-col gap-6 w-full"
                  onSubmit={handleSubmit}
                >
                  <div className="flex flex-col min-[360px]:flex-row justify-between gap-x-2 gap-y-4">
                    <Form.Field name="first_name" className="basis-6/12">
                      <Form.Label>First Name</Form.Label>

                      <Form.Input
                        type="text"
                        placeholder="First Name"
                        required
                        defaultValue={fieldData.firstName}
                        value={fieldData.firstName}
                        onChange={handleInputChange}
                        name="firstName"
                        autoFocus
                        autoCapitalize="words"
                        autoComplete="given-name"
                      >
                        First Name
                      </Form.Input>
                      <Form.Message match="valueMissing">
                        Please fill out this field.
                      </Form.Message>
                    </Form.Field>
                    <Form.Field name="last_name" className="basis-6/12">
                      <Form.Label>Last Name</Form.Label>

                      <Form.Input
                        type="text"
                        placeholder="Last Name"
                        required
                        defaultValue={fieldData.lastName}
                        value={fieldData.lastName}
                        onChange={handleInputChange}
                        name="lastName"
                        autoCapitalize="words"
                        autoComplete="family-name"
                      >
                        Last Name
                      </Form.Input>
                      <Form.Message match="valueMissing">
                        Please fill out this field.
                      </Form.Message>
                    </Form.Field>
                  </div>
                  {isLoaded && !oauthContinue && (
                    <Form.Field name="email">
                      <Form.Label>Email Address</Form.Label>

                      <Form.Input
                        type="email"
                        placeholder="Email Address"
                        required
                        defaultValue={fieldData.email}
                        value={fieldData.email}
                        onChange={handleInputChange}
                        name="email"
                        autoComplete="email"
                      >
                        Email Address
                      </Form.Input>
                      <Form.Message match="valueMissing">
                        Please fill out this field.
                      </Form.Message>
                    </Form.Field>
                  )}

                  <Form.Field name="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Input
                      type="text"
                      placeholder="Username"
                      required
                      defaultValue={fieldData.username}
                      value={fieldData.username}
                      onChange={handleInputChange}
                      name="username"
                      autoComplete="username"
                    >
                      Username
                    </Form.Input>
                    <Form.Message match="valueMissing">
                      Please fill out this field.
                    </Form.Message>
                  </Form.Field>
                  {isLoaded && !oauthContinue && (
                    <>
                      {/* <Form.Field name="password">
                        <Form.Label>Password</Form.Label>

                        <Form.PasswordInput
                          type="password"
                          placeholder="Password"
                          required
                          name="password"
                          autoComplete="new-password"
                          onChange={handleInputChange}
                          value={fieldData.password}
                        >
                          Password
                        </Form.PasswordInput>
                        <Form.Message match="valueMissing">
                          Please fill out this field.
                        </Form.Message>
                      </Form.Field> */}
                    </>
                  )}
                  <div className="flex flex-col gap-y-4">
                    {error && <div className="text-error-500">{error}</div>}
                    {birthdateError && (
                      <div className="text-error-500">
                        Please provide a valid birthdate.
                      </div>
                    )}
                    <div className="flex flex-col min-[360px]:flex-row justify-between gap-x-2 gap-y-4">
                      <Form.Field
                        name="month"
                        className="flex-grow min-w-0 w-full min-[360px]:w-auto"
                      >
                        <Form.Label static>Date of Birth</Form.Label>
                        <Form.Select
                          label="Month"
                          options={months}
                          height={12}
                          name="month"
                          onValueChange={(value) =>
                            handleBirthdateChange("month", value)
                          }
                          defaultValue={birthdate.month || ""}
                        />
                      </Form.Field>
                      <Form.Field
                        name="day"
                        className="flex-grow min-w-0 w-full min-[360px]:w-auto"
                      >
                        <Form.Select
                          label="Day"
                          options={dropdownDays}
                          height={12}
                          name="day"
                          onValueChange={(value) =>
                            handleBirthdateChange("day", value)
                          }
                          defaultValue={birthdate.day || ""}
                        />
                      </Form.Field>
                      <Form.Field
                        name="year"
                        className="flex-grow min-w-0 w-full min-[360px]:w-auto"
                      >
                        <Form.Select
                          label="Year"
                          options={years}
                          height={12}
                          name="year"
                          value={birthdate.year || ""}
                          onValueChange={(value) =>
                            handleBirthdateChange("year", value)
                          }
                          defaultValue={birthdate.year || ""}
                        />
                      </Form.Field>
                    </div>
                  </div>

                  <Checkbox
                    id="receive_updates"
                    name="receive_updates"
                    label="Yes, I'd like to receive newsletters, offers, and updates."
                    checked={fieldData.newsletter}
                    onCheckedChange={(checked: CheckedState) =>
                      setFieldData({
                        ...fieldData,
                        newsletter: checked,
                      })
                    }
                  />
                  <Form.Submit variant="filled">Create Account</Form.Submit>
                </Form>

                {isLoaded && !oauthContinue && (
                  <>
                    <div className="w-full text-center gap">
                      <p className="gap-text text-secondary-500 text-sm">or</p>
                    </div>
                    <p className="text-title-medium uppercase">Sign up with</p>
                    <button
                      onClick={() => signUpWithGoogle()}
                      className="w-full h-11 btn-social"
                    >
                      <img
                        src="/logos/google-logo.svg"
                        alt="Google Logo"
                        className="mr-2"
                      />
                      <img src="/logos/google-text.svg" alt="Google Logo" />
                    </button>
                  </>
                )}

                <p className="text-center text-secondary-300 text-sm pt-4">
                  Already have an account?{" "}
                  <button
                    onClick={() => setAuth("login")}
                    className="text-primary-200 underline"
                  >
                    Login
                  </button>
                </p>
                {/* <p className="mt-4 mb-4 text-center text-secondary-500 text-sm">
                    By signing up, you acknowledge that you have read, understand, and agree to Moneyline's{" "}
                    <a href="/terms-conditions" className="underline">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a href="/privacy-policy" className="underline">
                      Privacy Policy
                    </a>
                    .
                  </p> */}
              </>
            )}

            {pendingVerification && (
              <div className="flex flex-col justify-center items-center gap-6 w-full max-w-md text-center">
                {/* <Info.Block
                  title="Enter Verification Code"
                  variant="success"
                  icon="mail"
                  message="Please check your email and enter the 6 digit code. Please note this code expires in 10 minutes."
                /> */}
                <div className="">
                  <h2 className="mb-6 text-center text-display-lg lg:text-display-xl uppercase">
                    Enter Verification Code
                  </h2>

                  <div className="text-secondary-500">
                    A verification code was sent to {fieldData.email}. Please
                    check your email and enter the 6 digit code.
                  </div>
                </div>

                {error && <div className="text-error-500">{error}</div>}

                <Form
                  onSubmit={onPressVerify}
                  className="flex flex-col gap-6 w-full"
                >
                  <Form.Field name="code">
                    <Form.Input
                      type="text"
                      placeholder="Email Verification Code"
                      required
                      value={code}
                      pattern="\d*" // Only allow numbers
                      className="text-center"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[0-9]{0,6}$/;
                        if (regex.test(value)) {
                          setCode(value);
                        }
                      }}
                      autoComplete="off"
                    >
                      Verification Code
                    </Form.Input>
                    {/* <Form.Message match="valid">Success</Form.Message> */}
                    <Form.Message match="valueMissing">
                      Please fill out this field.
                    </Form.Message>
                  </Form.Field>

                  <Form.Submit variant="filled">Continue</Form.Submit>
                </Form>

                <div className="flex items-center gap-8">
                  <div>
                    <Form.Submit variant="outlined" onClick={changeDetails}>
                      Change Email Address
                    </Form.Submit>
                  </div>
                  {pendingVerification && (
                    <div>
                      <Form.Submit
                        variant="outlined"
                        onClick={resendVerification}
                      >
                        Resend
                      </Form.Submit>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <p className="mt-4 mb-4 text-center text-secondary-500 text-sm pb-12">
            By signing up, you acknowledge that you have read, understand, and
            agree to Moneyline's{" "}
            <a href="/terms-conditions" className="underline">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="/privacy-policy" className="underline">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      )}
    </Suspense>
  );
}
