"use client";
import Icon from "@/components/primitives/icon";
import Form from "@/components/primitives/form";
import Button from "../primitives/button";
import { useSignIn } from "@clerk/nextjs";
import { Suspense, SyntheticEvent, useContext, useState } from "react";
import Info from "../primitives/info";
import { useRouter } from "next/navigation";
import { useQueryUpdater } from "../navigation/query-updater";
import Link from "next/link";
import { AuthContext } from "@/app/(public)/drawer";

export default function ResetPasswordComponent() {
  const [error, setError] = useState(null);
  const { createQueryParamString, removeQueryParam } = useQueryUpdater();
  const { setAuth, closeAuth } = useContext(AuthContext);

  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [complete, setComplete] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);

  const { isLoaded, signIn, setActive } = useSignIn();

  if (!isLoaded) {
    return null;
  }

  async function create(e: SyntheticEvent) {
    e.preventDefault();

    await signIn
      ?.create({
        strategy: "reset_password_email_code",
        identifier: email,
      })
      .then((_) => {
        setSuccessfulCreation(true);
        setError(null);
      })
      .catch((err) => {
        console.error("error", err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
      });
  }

  async function reset(e: SyntheticEvent) {
    e.preventDefault();
    await signIn
      ?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code,
        password,
      })
      .then((result) => {
        if (result.status === "needs_second_factor") {
          setError(null);
          setSecondFactor(true);
        } else if (result.status === "complete") {
          setError(null);
          setActive({ session: result.createdSessionId });
          setComplete(true);
        } else {
          setError(null);
          console.log(result);
        }
      })
      .catch((err) => {
        console.error("error", err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
      });
  }
  return (
    <Suspense fallback={<div>loading...</div>}>


        {error && <Info.Inline variant="error" message={error} filled />}

        {!successfulCreation && !complete && (
          <div className="flex flex-col mt-10 mb-10 gap-6 justify-center w-full max-w-md">
            <h2 className="text-display uppercase">Forgot your Password?</h2>
            <Form className="flex flex-col gap-6" onSubmit={create}>
              <Form.Field name="email">
                <Form.Input
                  type="text"
                  placeholder="Email address"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                >
                  Email address
                </Form.Input>
                <Form.Message match="valueMissing">
                  Please fill out this field.
                </Form.Message>
              </Form.Field>

              <Form.Submit variant="filled">Reset Password</Form.Submit>
            </Form>
            <p className="text-sm text-secondary-500 text-center">
              <button
                onClick={() => setAuth("login")}
                className="underline text-secondary-300"
              >
                Back to login
              </button>
            </p>
          </div>
        )}

        {successfulCreation && !complete && (
          <div className="flex flex-col mt-10 mb-10 gap-8 justify-center w-full max-w-md">
            <div className="flex flex-col w-full gap-4">
              <h2 className="text-display uppercase">Reset Password</h2>
              <p className="text-secondary-500">
                An email with a verification code was sent to your email. Please
                enter the verification code and a new password to gain access to
                your account.
              </p>
            </div>
            <Form onSubmit={reset} className="flex flex-col gap-6">
              <Form.Field name="code">
                <Form.Input
                  type="text"
                  placeholder="Verification Code"
                  required
                  value={code}
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[0-9]{0,6}$/; // Regex pattern to allow only numbers and limit to 6 characters
                    if (regex.test(value)) {
                      setCode(value);
                    }
                  }}
                  autoComplete="off"
                >
                  Verification Code
                </Form.Input>
                <Form.Message match="valueMissing">
                  Please fill out this field.
                </Form.Message>
              </Form.Field>
              <Form.Field name="New Password">
                <Form.Input
                  type="password"
                  placeholder="New Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                >
                  New Password
                </Form.Input>
                <Form.Message match="valueMissing">
                  Please fill out this field.
                </Form.Message>
              </Form.Field>
              <Form.Field name="Confirm New Password">
                <Form.Input
                  type="password"
                  placeholder="Confirm New Password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                >
                  Confirm New Password
                </Form.Input>
                <Form.Message match="valueMissing">
                  Please fill out this field.
                </Form.Message>
              </Form.Field>

              <Form.Submit variant="filled">Reset Password</Form.Submit>
            </Form>
          </div>
        )}

        {complete && (
          <div className="flex flex-col gap-14 justify-center items-center w-full max-w-md">
            <Info.Block
              title="Success"
              variant="success"
              message="Your password has been updated and you have been logged in."
              confetti
            />
            <Form.Submit variant="filled" onClick={() => closeAuth()}>
              Done
            </Form.Submit>
          </div>
        )}
        {secondFactor && "2FA is required, this UI does not handle that"}
    </Suspense>

  );
}
